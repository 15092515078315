import React from 'react'
import FunctionalCard from '../../../components/functionalCard/FunctionalCard'
import graduationCap from '../../../assets/cap.png'
import settings from '../../../assets/settings.png'
import admin from '../../../assets/admin.png'
import './FunctionalitySection.css'

function FunctionalitySection() {
  return (
    <div className='functionalitySection'>
      <div className='container-fluid'>
        <div className='container'>
          <div className='row'>

            <div className='col-md-12 col-lg-4 pt-4 pt-md-0'>
              <div className='FunctionalBorder'>
                <FunctionalCard
                  image={graduationCap}
                  text="Specialized designs for school, college, coaching and Find tutors"
                />
              </div>
            </div>

            <div className='col-md-12 col-lg-4 pt-4 pt-md-0'>
              <div className='FunctionalBorder'>
                <FunctionalCard
                  image={settings}
                  text="The Software equipped with fully functional features"
                />
              </div>
            </div>

            <div className='col-md-12 col-lg-4 pt-4 pt-lg-0'>
              <div className='FunctionalBorderLast'>
                <FunctionalCard
                  image={admin}
                  text="Manage your system with ease by powerful admin panel"
                />
              </div>
            </div>

          </div>

        </div>

      </div>

    </div>
  )
}

export default FunctionalitySection