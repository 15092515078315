import React from 'react'
import coverImage from "../../../assets/homeCover.png"
import FunctionalitySection from '../functionalitySection/FunctionalitySection'
import './CoverSection.css'

function CoverSection() {
    return (
        <section id='home'>
            <div className='coverSection'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-6 col-lg-6 pe-5'>
                            <div className='descriptionWrapper'>
                                <p>Biddatech</p>
                                <h1>Elevate your educational institution with our advanced  management system</h1>
                                <a href='#contact'>Contact Us</a>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-6 coverImageWrapper mt-5 mt-md-5 mt-lg-0'>
                            <img className='img-fluid' src={coverImage} />
                        </div>

                    </div>
                </div>
            </div>
            <FunctionalitySection />
        </section>

    )
}

export default CoverSection