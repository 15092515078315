
import './App.css';
import ContactInfo from './components/contactInfo/ContactInfo';
import Footer from './components/footer/Footer';
import Support from './components/support/Support';
import CoverSection from './pages/homepage/coverSection/CoverSection';
import FeatureSection from './pages/homepage/featureSection/FeatureSection';
import MainRoutes from './routes/MainRoutes';


function App() {
  return (
    <div>
      <MainRoutes />
      <CoverSection />
      <FeatureSection />
      <Support />
      <ContactInfo />
      <Footer />
    </div>
  );
}

export default App;
