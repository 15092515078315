import React from 'react'
import logoImage from "../../assets/logo.png"
import facebook from '../../assets/footerFacebook.png'
import youtube from '../../assets/footerYouTube.png'
import linked from '../../assets/footerLinked.png'
import './Footer.css'

function Footer() {
    return (
        <section id='footer'>
            <div className='footerContainer'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <div className='logoContainer'>
                                <img src={logoImage} height={60} />
                            </div>

                        </div>

                        <div className='col-12 col-md-6 col-lg-8 locationContainer'>
                            <h3>Head Office (<span style={{ color: "var(--primary-color" }}>GoJustitech</span>)</h3>
                            <p>Level 04, Awal Center, 34 Kemal Ataturk avenue, Banani, Dhaka-1213.</p>
                        </div>

                        {/* <div className='col-12 col-md-6 col-lg-3 contactContainer'>
                        <p>Phone: +880 1304349793</p>
                        <p>E-mail: gojustitech@gmail.com</p>
                    </div>
                    <div className='col-12 col-md-6 col-lg-3 '>
                        <div className='socialLinkContainer' >
                            <div className='socialLinks'>
                                <img src={facebook} height={25} />
                            </div>
                            <div className='socialLinks ms-3'>
                                <img src={youtube} height={25} />
                            </div>
                            <div className='socialLinks ms-3'>
                                <img src={linked} height={25} />
                            </div>
                        </div>
                    </div> */}

                    </div>
                </div>

                <div className='borderLine'>
                </div>

                <div className='container'>
                    <div className='copyright'>
                        <p>copyright©  All rights reserved by gojustitech</p>
                    </div>
                </div>

            </div>
        </section>

    )
}

export default Footer