

import React, { useState, useEffect } from 'react'
import LogoImage from '../../components/topNav/logoImage/logo.png'
import ContactIcon from '../../components/topNav/logoImage/contactIcon.png'
import NavIcon from '../../components/topNav/logoImage/NavIcon.png'
import CloseBtn from '../../components/topNav/logoImage/closeBtn.png'
import SideNav from '../../components/topNav/sideNav/SideNav'
import ActiveIcon from '../../components/topNav/logoImage/activeIcon.png'
import './TopNav.css'

function TopNav() {
    const [openMenu, setOpenMenu] = useState(false)
    const [activeSection, setActiveSection] = useState('home')

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    const handleScroll = () => {
        const homeSection = document.getElementById('home')
        const featuresSection = document.getElementById('features')
        const supportSection = document.getElementById('support')
        const contactSection = document.getElementById('contact')

        if (
            contactSection.getBoundingClientRect().top <= window.innerHeight * 0.5 &&
            contactSection.getBoundingClientRect().top >= -contactSection.offsetHeight + window.innerHeight * 0.5
        ) {
            setActiveSection('contact')
        } else if (
            supportSection.getBoundingClientRect().top <= window.innerHeight * 0.5 &&
            supportSection.getBoundingClientRect().top >= -supportSection.offsetHeight + window.innerHeight * 0.5
        ) {
            setActiveSection('support')
        } else if (
            featuresSection.getBoundingClientRect().top <= window.innerHeight * 0.5 &&
            featuresSection.getBoundingClientRect().top >= -featuresSection.offsetHeight + window.innerHeight * 0.5
        ) {
            setActiveSection('features')
        } else {
            setActiveSection('home')
        }
    }

    return (
        <>
            <div className="topNavContainer">
                <div className="container">
                    <nav>
                        <div className="mainLogo">
                            <a to="#home">
                                <img src={LogoImage} height={60} />
                            </a>
                        </div>

                        <div className="d-flex align-items-center">
                            <div className="NavbarItem">
                                <a
                                    href="#home"
                                    id="#home"
                                    onClick={() => setActiveSection('home')}
                                    className={`NavbarLink ${activeSection === 'home' ? 'active' : ''}`}
                                >
                                    <h6>HOME</h6>
                                    {activeSection === 'home' && (
                                        <div className="d-flex justify-content-center">
                                            <img src={ActiveIcon} height={15} />
                                        </div>
                                    )}
                                </a>
                                <a
                                    href="#features"
                                    id="#features"
                                    onClick={() => setActiveSection('features')}
                                    className={`NavbarLink ${activeSection === 'features' ? 'active' : ''}`}
                                >
                                    <h6>FEATURES</h6>
                                    {activeSection === 'features' && (
                                        <div className="d-flex justify-content-center">
                                            <img src={ActiveIcon} height={15} />
                                        </div>
                                    )}
                                </a>

                                <a
                                    href="#support"
                                    id="#support"
                                    onClick={() => setActiveSection('support')}
                                    className={`NavbarLink ${activeSection === 'support' ? 'active' : ''}`}
                                >
                                    <h6>SUPPORT</h6>
                                    {activeSection === 'support' && (
                                        <div className="d-flex justify-content-center">
                                            <img src={ActiveIcon} height={15} />
                                        </div>
                                    )}
                                </a>
                                <a
                                    href="#contact"
                                    id="#contact"
                                    onClick={() => setActiveSection('contact')}
                                    className={`NavbarLink ${activeSection === 'contact' ? 'active' : ''}`}
                                >
                                    <h6>CONTACT</h6>
                                    {activeSection === 'contact' && (
                                        <div className="d-flex justify-content-center">
                                            <img src={ActiveIcon} height={15} />
                                        </div>
                                    )}
                                </a>
                            </div>

                            <div className='Contact_us'>
                                <a href='#contact' className='Contact_Btn'>
                                    <img src={ContactIcon} height={40} alt='contact' />
                                    <span>+8801558503791</span>
                                </a>
                            </div>


                            <button onClick={() => setOpenMenu(!openMenu)} className='OpenBtn'>
                                <img src={NavIcon} height={17} />
                            </button>
                        </div>


                    </nav>
                </div>


                {openMenu ? (
                    <div className='SideNavOpen'>
                        <div className='d-flex justify-content-end m-3'>
                            <button onClick={() => setOpenMenu(!openMenu)} className="CloseBtn">
                                <img src={CloseBtn} height={30} />
                            </button>
                        </div>

                        <SideNav />
                    </div>
                ) : (
                    <div className='SideNavClose'>
                        <div className='d-flex justify-content-end m-3'>
                            <button onClick={() => setOpenMenu(!openMenu)} className="CloseBtn">
                                <img src={CloseBtn} height={30} />
                            </button>
                        </div>

                        <SideNav />
                    </div>
                )}


            </div>
        </>

    )
}
export default TopNav

    //     const handleScroll = () => {
    //         const homeSection = document.getElementById('home');
    //         const featureSection = document.getElementById('features');
    //         const supportSection = document.getElementById('support');
    //         const contactSection = document.getElementById('contact');

    //         if (contactSection.offsetTop <= window.pageYOffset) {
    //             setActiveSection('contact');
    //         } else if (supportSection.offsetTop <= window.pageYOffset) {
    //             setActiveSection('support');
    //         } else if (featureSection.offsetTop <= window.pageYOffset) {
    //             setActiveSection('features');
    //         } else if (homeSection.offsetTop <= window.pageYOffset) {
    //             setActiveSection('home');
    //         }
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    // const handleNavClick = (section) => {
    //     const sectionElement = document.getElementById(section);
    //     sectionElement.scrollIntoView({ behavior: 'smooth' });
    // };