
// import React, { useState } from 'react'
// import './SideNav.css'
// import ContactIcon from '../logoImage/contactWhite.png'

// function SideNav() {

//     const [onChangeColor, setOnChangeColor] = useState('home');

//     return (
//         <>
//             <div className='SideNav_Content'>
//                 <div className='NavbarItem'>
//                     <a onClick={() => setOnChangeColor('home')} href='#home' className='NavbarLink'>
//                         {<h6 className={onChangeColor === 'home' ? 'active' : null}>HOME</h6>}
//                     </a>
//                     <a onClick={() => setOnChangeColor('features')} href='#features' className='NavbarLink'>
//                         <h6 className={onChangeColor === 'features' ? 'active' : null}>FEATURES</h6>
//                     </a>
//                     <a onClick={() => setOnChangeColor('support')} href='#support' className='NavbarLink '>
//                         <h6 className={onChangeColor === 'support' ? 'active' : null}>SUPPORT</h6>
//                     </a>
//                     <a onClick={() => setOnChangeColor('contact')} href='#contact' className='NavbarLink '>
//                         <h6 className={onChangeColor === 'contact' ? 'active' : null}>CONTACT</h6>
//                     </a>
//                     <div className='Contact_us'>
//                         <a href='#contact' className='Contact_Btn'>
//                             <img src={ContactIcon} height={40} />
//                             <span>+8801558503791</span>
//                         </a>
//                     </div>

//                 </div>

//             </div>


//         </>
//     )
// }

// export default SideNav

import React, { useState, useEffect } from 'react';
import './SideNav.css';
import ContactIcon from '../logoImage/contactWhite.png';

function SideNav() {
  const [activeSection, setActiveSection] = useState('home');

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { rootMargin: '-50% 0px -50% 0px' }
    );

    document.querySelectorAll('section[id]').forEach((section) => {
      observer.observe(section);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <div className='SideNav_Content'>
        <div className='NavbarItem'>
          <a href='#home' className='NavbarLink'>
            <h6 className={activeSection === 'home' ? 'active' : null}>HOME</h6>
          </a>
          <a href='#features' className='NavbarLink'>
            <h6 className={activeSection === 'features' ? 'active' : null}>FEATURES</h6>
          </a>
          <a href='#support' className='NavbarLink '>
            <h6 className={activeSection === 'support' ? 'active' : null}>SUPPORT</h6>
          </a>
          <a href='#contact' className='NavbarLink '>
            <h6 className={activeSection === 'contact' ? 'active' : null}>CONTACT</h6>
          </a>
          <div className='Contact_us'>
            <a href='#contact' className='Contact_Btn'>
              <img src={ContactIcon} height={40} />
              <span>+8801558503791</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default SideNav;

