import { BrowserRouter as Router,Routes, Route } from 'react-router-dom'
import React from 'react'
import TopNav from '../components/topNav/TopNav'
import Home from '../pages/home/Home'



function MainRoutes() {

    return (
        <>
            <Router>
                <TopNav />
                <Routes>
                    <Route path="/" element={<Home />} />
                </Routes>
            </Router>
        </>
    )
}

export default MainRoutes
