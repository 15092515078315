import React, { useState } from 'react'
import './Support.css'
import { ModalHeader, Modal, ModalBody } from 'reactstrap'
import LiveIcon from '../../assets/liveIcon.png'
import VideoIcon from '../../assets/VideoIcon.png'

function Support() {

    const [data, setData] = useState(false)

    return (
        <>

            <Modal
                isOpen={data}
                toggle={() => setData(!data)}
                size='xl'
                className='MainBackground'
            >

                <ModalHeader className='ModalHeaderSection' toggle={() => setData(!data)}></ModalHeader>
                <ModalBody>
                    <h1 className='text-center py-5'>Coming Soon</h1>
                </ModalBody>
            </Modal>


            <section id='support'>
                <div className='Support'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='Title mb-4' >
                                    <h5>Need help ?</h5>
                                    <h3>We've got you covered with our expert after sale support</h3>
                                </div>
                            </div>
                            <div className='col-md-12 col-lg-6 mt-4'>
                                <div className='Contact_Doc' onClick={() => setData(!data)}>
                                    <img src={LiveIcon} height={60} />
                                    <h4>Live Support</h4>
                                </div>
                            </div>
                            <div className='col-md-12 col-lg-6 mt-4'>
                                <div className='Contact_Doc' onClick={() => setData(!data)}>
                                    <img src={VideoIcon} height={60} />
                                    <h4>Video Explanation</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default Support
