import React from 'react'
import './FunctionalCard.css'

function FunctionalCard(props) {
    return (
        <div className='cardContainer'>
            <div className='imgContainer'>
                <img height={60} src={props.image} />
            </div>
            <p>{props.text}</p>
        </div>
    )
}

export default FunctionalCard