import React from 'react'
import './ContactInfo.css'
import PeopleIcon from '../../assets/formIcons/peopleIcon.png'
import BookIcon from '../../assets/formIcons/bookIcon.png'
import CallIcon from '../../assets/formIcons/callIcon.png'
import MailIcon from '../../assets/formIcons/mailIcon.png'
import SmsIcon from '../../assets/formIcons/smsIcon.png'
import SendIcon from '../../assets/formIcons/SendIcon.png'
import BlackMailIcon from '../../assets/formIcons/BlackMailIcon.png'
import Youtube from '../../assets/formIcons/youtube.png'
import Facebook from '../../assets/formIcons/facebook.png'
import Linkedin from '../../assets/formIcons/linkedin.png'

function ContactInfo() {
    return (
        <>
            <section id='contact'>
                <div className='ContactInfo py-5'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12 col-lg-6 Padding-1'>
                                <div className='ContactDetails'>
                                    <div>
                                        <h5>Get In Touch With Us</h5>
                                        <h1>+8801558503791</h1>
                                        <div className='d-flex align-items-center'>
                                            <img src={BlackMailIcon} height={25} />
                                            <h6>info@biddatech.com</h6>
                                        </div>

                                            <div className='SocialBtn'>
                                                <button>
                                                    <img src={Facebook} height={40} />
                                                </button>
                                                <button className='ms-3'>
                                                    <img src={Linkedin} height={40} />
                                                </button>
                                                <button className='ms-3'>
                                                    <img src={Youtube} height={40} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-12 col-lg-6 Padding-2'>
                                    <div className='ContactForm'>
                                        <form>
                                            <div className="InputType d-flex align-items-center">
                                                <img src={PeopleIcon} height={23} />
                                                <input type="text" class="form-control" placeholder="Name" />
                                            </div>
                                            <div className="InputType d-flex align-items-center">
                                                <img src={MailIcon} height={23} />
                                                <input type="text" class="form-control" placeholder="Email" />
                                            </div>
                                            <div className="InputType d-flex align-items-center">
                                                <img src={CallIcon} height={23} />
                                                <input type="text" class="form-control" placeholder="Phone" />
                                            </div>
                                            <div className="InputType d-flex align-items-center">
                                                <img src={BookIcon} height={23} />
                                                <input type="text" class="form-control" placeholder="Institute" />
                                            </div>
                                            <div className="InputType d-flex align-items-center">
                                                <img className='TextArea' src={SmsIcon} height={23} />
                                                <textarea className='form-control' rows="5" cols="50" placeholder='Message'></textarea>
                                            </div>

                                            <div className='d-flex justify-content-end'>
                                                <button>
                                                    Submit
                                                    <img src={SendIcon} height={20} />
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
        </>
    )
}

export default ContactInfo
