import React from 'react'
import FeatureCard from '../../../components/featureCard/FeatureCard'
import './FeatureSection.css'

function FeatureSection() {
    return (
        <section id='features'>
            <div className='featureContainer'>
                <div className='container'>
                    <div className='coreFeature'>
                        <h2>
                            CORE FEATURES
                        </h2>

                        <p>
                            <span style={{ color: "#FFBF43" }}>Biddatech</span> Where education and technology meet with all features and functionalities
                        </p>
                    </div>
                    <FeatureCard />
                </div>

            </div>
        </section>

    )
}

export default FeatureSection