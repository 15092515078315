import React  from 'react'
import ContactInfo from '../../components/contactInfo/ContactInfo'
import Footer from '../../components/footer/Footer'
import Support from '../../components/support/Support'
import CoverSection from '../homepage/coverSection/CoverSection'
import FeatureSection from '../homepage/featureSection/FeatureSection'


function Home() {

    <div>

        <CoverSection />
        <FeatureSection />
        <Support />
        <ContactInfo />
        <Footer />

    </div>
        

}
export default Home
