import React from 'react'
import './FeatureCard.css'
import admission from "../../assets/admission.png"
import profile from "../../assets/profile.png"
import notice from "../../assets/notice.png"
import payment from "../../assets/payment.png"
import attendance from "../../assets/attendance.png"
import schedule from "../../assets/schedule.png"
import accounts from "../../assets/accounts.png"
import result from "../../assets/result.png"


function FeatureCard() {

    const data = [
        {
            image: admission,
            featureName: "Admission",
            description: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."
        },
        {
            image: profile,
            featureName: "Profile",
            description:"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."
        },
        {
            image: notice,
            featureName: "Notice",
            description:"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."
        },
     
        {
            image: schedule,
            featureName: "Schedule",
            description:"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."
        },
        {
            image: accounts,
            featureName: "Accounts",
            description:"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."
        },
        {
            image: result,
            featureName: "Result",
            description:"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."
        },
        {
            image: payment,
            featureName: "Payment",
            description:"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."
        },
        {
            image: attendance,
            featureName: "Attendance",
            description:"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."
        },

    ]

    return (
        <>
            <div className='featureCardContainer'>
                <div className='row' >
                    {data.map((data) =>
                    (
                        <div className='col-12 col-md-6 col-lg-4 mt-5 pt-4 featureCard  '>
                            <div className='imgContainer'>
                                <img height={55} src={data.image} />
                            </div>

                            <h3>{data.featureName}</h3>
                            <p>{data.description}</p>
                        </div>
                    )
                    )}
                </div>
            </div>

        </>
    )
}

export default FeatureCard